var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "px-0 mx-0 mb-5",
      staticStyle: { "max-width": "600px" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        { staticClass: "mb-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.$vuetify.breakpoint.xsOnly
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { "vertical-align": "middle" },
                      attrs: { text: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("backtoUserSetting")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "text-h5",
                  staticStyle: { "vertical-align": "middle" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("changePasswordHeader")) + " ")]
              ),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.xsOnly
            ? _c("v-col", { attrs: { cols: "12" } }, [_c("v-divider")], 1)
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-form",
        { ref: "formChangePassword" },
        [
          _c(
            "v-row",
            {
              staticClass: "mb-3",
              attrs: { "no-gutters": "", justify: "start" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    staticClass: "mb-1",
                    attrs: {
                      outlined: "",
                      "hide-details": "true",
                      "append-icon": _vm.showCurrentPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      type: _vm.showCurrentPassword ? "text" : "password",
                      label: _vm.$t("currentPasswordText"),
                    },
                    on: {
                      input: _vm.checkPassword,
                      "click:append": function ($event) {
                        _vm.showCurrentPassword = !_vm.showCurrentPassword
                      },
                      contextmenu: function ($event) {
                        $event.preventDefault()
                      },
                      copy: function ($event) {
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.oldPassword,
                      callback: function ($$v) {
                        _vm.oldPassword = $$v
                      },
                      expression: "oldPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mb-3",
              attrs: { "no-gutters": "", justify: "start" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pb-2 rounded-lg",
                      attrs: { outlined: "", color: "grey lighten-4" },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "body-2 mb-0 font-weight-medium" },
                        [_vm._v(" " + _vm._s(_vm.$t("passwordMustText")) + " ")]
                      ),
                      _c(
                        "v-container",
                        {
                          staticClass: "ml-4",
                          attrs: { caption: "", "font-weight-regular": "" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    size: "12",
                                    color: _vm.containsEightCharacters
                                      ? "success"
                                      : "grey darken-4",
                                  },
                                },
                                [_vm._v(" mdi-checkbox-blank-circle ")]
                              ),
                              _c(
                                "span",
                                {
                                  class: _vm.containsEightCharacters
                                    ? "success--text"
                                    : "grey-darken-4--text",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("charactersLengthText")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    size: "12",
                                    color: _vm.containsNumber
                                      ? "success"
                                      : "grey darken-4",
                                  },
                                },
                                [_vm._v(" mdi-checkbox-blank-circle ")]
                              ),
                              _c(
                                "span",
                                {
                                  class: _vm.containsNumber
                                    ? "success--text"
                                    : "grey-darken-4--text",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("numberText")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    size: "12",
                                    color:
                                      _vm.containsUppercase &&
                                      _vm.containsLowercase
                                        ? "success"
                                        : "grey darken-4",
                                  },
                                },
                                [_vm._v(" mdi-checkbox-blank-circle ")]
                              ),
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.containsUppercase &&
                                    _vm.containsLowercase
                                      ? "success--text"
                                      : "grey-darken-4--text",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("upperLowerLettersText")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    size: "12",
                                    color: _vm.containsSpecialCharacter
                                      ? "success"
                                      : "grey darken-4",
                                  },
                                },
                                [_vm._v(" mdi-checkbox-blank-circle ")]
                              ),
                              _c(
                                "span",
                                {
                                  class: _vm.containsSpecialCharacter
                                    ? "success--text"
                                    : "grey-darken-4--text",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("specialCharacterText")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-row", [
                            _c(
                              "div",
                              {
                                staticClass: "font-italic ml-4 text-wrap mr-3",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("avoidDictionaryText")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mb-3",
              attrs: { "no-gutters": "", justify: "start" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    staticClass: "mb-1",
                    attrs: {
                      outlined: "",
                      "hide-details": "true",
                      "append-icon": _vm.showNewPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      type: _vm.showNewPassword ? "text" : "password",
                      label: _vm.$t("newPasswordText"),
                    },
                    on: {
                      input: _vm.checkPassword,
                      "click:append": function ($event) {
                        _vm.showNewPassword = !_vm.showNewPassword
                      },
                      contextmenu: function ($event) {
                        $event.preventDefault()
                      },
                      copy: function ($event) {
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.newPassword,
                      callback: function ($$v) {
                        _vm.newPassword = $$v
                      },
                      expression: "newPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mb-3",
              attrs: { "no-gutters": "", justify: "start" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "11" } },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      "aria-label": _vm.score.value,
                      rounded: "",
                      bottom: "",
                      height: "7",
                      color: _vm.score.color,
                      value: _vm.score.value,
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c(
                  "div",
                  {
                    staticClass: "caption pl-2",
                    class: _vm.score.color + "--text",
                  },
                  [_vm._v(" " + _vm._s(_vm.score.strength) + " ")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mb-3",
              attrs: { "no-gutters": "", justify: "start" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    staticClass: "mb-1",
                    attrs: {
                      outlined: "",
                      "append-icon": _vm.showConfirmPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      type: _vm.showConfirmPassword ? "text" : "password",
                      label: _vm.$t("confirmPassword"),
                      rules: [_vm.rules.confirmPasswordRules],
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showConfirmPassword = !_vm.showConfirmPassword
                      },
                      contextmenu: function ($event) {
                        $event.preventDefault()
                      },
                      copy: function ($event) {
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function ($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "start" } },
            [
              _c(
                "v-row",
                [
                  _vm.$vuetify.breakpoint.smAndDown
                    ? _c(
                        "v-bottom-navigation",
                        {
                          staticClass: "px-2 py-4",
                          attrs: { fixed: "", horizontal: "", height: "70" },
                          model: {
                            value: _vm.bottomNavbar,
                            callback: function ($$v) {
                              _vm.bottomNavbar = $$v
                            },
                            expression: "bottomNavbar",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "px-4 py-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-h6 rounded-lg",
                                  attrs: {
                                    color: "primary",
                                    block: "",
                                    large: "",
                                    disabled: !_vm.checkValidPassword,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v(" Update ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                color: "primary",
                                disabled: !_vm.checkValidPassword,
                              },
                              on: { click: _vm.submit },
                            },
                            [_vm._v(" Update ")]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }