<template>
  <v-container fluid class="px-0 mx-0 mb-5" style="max-width: 600px">
    <v-row class="mb-3">
      <v-col cols="12">
        <v-btn
          v-if="$vuetify.breakpoint.xsOnly"
          text
          x-small
          style="vertical-align: middle"
          @click="$emit('backtoUserSetting')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="text-h5" style="vertical-align: middle">
          {{ $t("changePasswordHeader") }}
        </span>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-form ref="formChangePassword">
      <v-row no-gutters justify="start" class="mb-3">
        <v-col cols="12">
          <v-text-field
            v-model="oldPassword"
            outlined
            hide-details="true"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showCurrentPassword ? 'text' : 'password'"
            :label="$t('currentPasswordText')"
            class="mb-1"
            @input="checkPassword"
            @click:append="showCurrentPassword = !showCurrentPassword"
            @click.right.prevent
            @copy.prevent
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters justify="start" class="mb-3">
        <v-col cols="12">
          <v-card class="pb-2 rounded-lg" outlined color="grey lighten-4">
            <v-card-title class="body-2 mb-0 font-weight-medium">
              {{ $t("passwordMustText") }}
            </v-card-title>

            <v-container caption font-weight-regular class="ml-4">
              <v-row>
                <v-icon
                  size="12"
                  class="mr-1"
                  :color="containsEightCharacters ? 'success' : 'grey darken-4'"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <span
                  :class="
                    containsEightCharacters
                      ? 'success--text'
                      : 'grey-darken-4--text'
                  "
                >
                  {{ $t("charactersLengthText") }}
                </span>
              </v-row>
              <v-row>
                <v-icon
                  size="12"
                  class="mr-1"
                  :color="containsNumber ? 'success' : 'grey darken-4'"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <span
                  :class="
                    containsNumber ? 'success--text' : 'grey-darken-4--text'
                  "
                >
                  {{ $t("numberText") }}
                </span>
              </v-row>
              <v-row>
                <v-icon
                  size="12"
                  class="mr-1"
                  :color="
                    containsUppercase && containsLowercase
                      ? 'success'
                      : 'grey darken-4'
                  "
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                <span
                  :class="
                    containsUppercase && containsLowercase
                      ? 'success--text'
                      : 'grey-darken-4--text'
                  "
                >
                  {{ $t("upperLowerLettersText") }}
                </span>
              </v-row>
              <v-row>
                <v-icon
                  size="12"
                  class="mr-1"
                  :color="
                    containsSpecialCharacter ? 'success' : 'grey darken-4'
                  "
                >
                  mdi-checkbox-blank-circle
                </v-icon>

                <span
                  :class="
                    containsSpecialCharacter
                      ? 'success--text'
                      : 'grey-darken-4--text'
                  "
                >
                  {{ $t("specialCharacterText") }}
                </span>
              </v-row>
              <v-row>
                <div class="font-italic ml-4 text-wrap mr-3">
                  {{ $t("avoidDictionaryText") }}
                </div>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters justify="start" class="mb-3">
        <v-col cols="12">
          <v-text-field
            v-model="newPassword"
            outlined
            hide-details="true"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showNewPassword ? 'text' : 'password'"
            :label="$t('newPasswordText')"
            class="mb-1"
            @input="checkPassword"
            @click:append="showNewPassword = !showNewPassword"
            @click.right.prevent
            @copy.prevent
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters justify="start" class="mb-3">
        <v-col cols="11">
          <v-progress-linear
            :aria-label="score.value"
            rounded
            bottom
            height="7"
            :color="score.color"
            :value="score.value"
          ></v-progress-linear>
        </v-col>
        <v-col cols="auto">
          <div :class="score.color + '--text'" class="caption pl-2">
            {{ score.strength }}
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters justify="start" class="mb-3">
        <v-col cols="12">
          <v-text-field
            v-model="confirmPassword"
            outlined
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            :label="$t('confirmPassword')"
            class="mb-1"
            :rules="[rules.confirmPasswordRules]"
            @click:append="showConfirmPassword = !showConfirmPassword"
            @click.right.prevent
            @copy.prevent
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters justify="start">
        <v-row>
          <v-bottom-navigation
            v-if="$vuetify.breakpoint.smAndDown"
            v-model="bottomNavbar"
            fixed
            horizontal
            height="70"
            class="px-2 py-4"
          >
            <v-row class="px-4 py-2">
              <v-btn
                color="primary"
                block
                large
                class="text-h6 rounded-lg"
                :disabled="!checkValidPassword"
                @click="submit"
              >
                Update
              </v-btn>
            </v-row>
          </v-bottom-navigation>
          <v-col v-else cols="12">
            <v-btn
              large
              color="primary"
              :disabled="!checkValidPassword"
              @click="submit"
            >
              Update
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import zxcvbn from "zxcvbn";
export default {
  data() {
    return {
      bottomNavbar: true,
      showNewPassword: false,
      showCurrentPassword: false,
      showConfirmPassword: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      validPassword: false,
      containsEightCharacters: false,
      containsNumber: false,
      containsUppercase: false,
      containsLowercase: false,
      containsSpecialCharacter: false,
      strength: "",
      rules: {
        confirmPasswordRules: (value) =>
          value === this.newPassword ||
          "Password and confirm password must be match.",
      },
    };
  },
  computed: {
    checkValidPassword() {
      return (
        this.newPassword === this.confirmPassword &&
        this.validPassword &&
        this.oldPassword &&
        this.newPassword !== this.oldPassword
      );
    },
    score() {
      const result = zxcvbn(this.newPassword);

      switch (result.score) {
        case 4:
          return {
            color: "primary",
            value: 100,
            strength: "Very Strong",
          };
        case 3:
          return {
            color: "success",
            value: 75,
            strength: "Strong",
          };
        case 2:
          return {
            color: "orange",
            value: 50,
            strength: "Average",
          };
        case 1:
          return {
            color: "error",
            value: 25,
            strength: "Weak",
          };
        default:
          return {
            color: "grey",
            value: 0,
            strength: "Weak",
          };
      }
    },
  },
  methods: {
    async submit() {
      let data = {
        userId: this.$store.state.User.user.user_id,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };
      await this.$emit("change-password", data);
    },
    resetForm() {
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.validPassword = false;
    },
    checkPassword() {
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
      this.containsEightCharacters = this.newPassword.length >= 8;
      this.containsNumber = /\d/.test(this.newPassword);
      this.containsUppercase = /[A-Z]/.test(this.newPassword);
      this.containsLowercase = /[a-z]/.test(this.newPassword);
      this.containsSpecialCharacter = format.test(this.newPassword);
      if (
        this.containsEightCharacters === true &&
        this.containsSpecialCharacter === true &&
        this.containsUppercase === true &&
        this.containsLowercase === true &&
        this.containsNumber === true
      ) {
        this.validPassword = true;
      } else {
        this.validPassword = false;
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: #f5f5f5 !important;
}
</style>
